@import '../../acumen/scss/core/mixin/sprite';

/* Site Wide Changes */

/* Category Changes */

.catalog-header {
  text-align: left;
  h1 {
    text-align: left;
  }
}

.product-grid {
  a.product-image:hover::before {
    border-color: #195f2f;
  }
  .product-name {
    color: #000;
    &:hover {
      color: #195f2f;
    }
  }
}

.res-480-show {
  display: none;
}

[data-column="dimensions"] {
  min-width: 50px;
}

@media only screen and (max-width: 480px) {
  .res-480 {
    *, thead th {
      font-size: 8pt;
    }
  }
  .res-480-hide {
    display: none;
  }
  .res-480-show {
    display: table-cell;
  }
}

/* Clean up footer */

footer.site-footer {
  background-color: #eee;
  padding-top: 35px;
  margin-top: 0;
  a {
    color: #000;
    &:hover {
      color: #195f2f;
    }
  }
}

.footer-spacer {
  margin-top: 80px;
}

/* Create Brand HR Strap */

.strap--brand-green {
  background-color: #195f2f;
  color: #f3f3f3;
}

.strap--brand-grey {
  background-color: #505868;
  color: #f3f3f3;
}

.strap--brand-green a, .strap--brand-grey a {
  color: #fff;
  font-weight: normal;
}

.strap--brand-green a:hover, .strap--brand-grey a:hover {
  color: #fff;
  text-decoration: underline;
}

#footer-visual {
  height: 300px;
  width: 100%;
  background-image: url('../media/visuals/our-industries.jpg');
}

/* Modify product carousel styling */

.cms-index-index {
  .product-name {
    color: #000;
    min-height: 60px;
  }
  .button--cta {
    background-color: #1f6b37;
    &:hover {
      background-color: #195f2f;
    }
  }
}

/* Modify Product Page */

.configurable-options-list label {
  width: auto;
}

/* Change to Brand Green Buttons */

.cart-checkout-methods .button, .subscribe-box .button, .mini-cart_actions a:nth-child(2), .page-title_actions .button, .add-to-cart .button {
  background-color: #1f6b37;
}

.cart-checkout-methods .button:hover, .subscribe-box .button:hover, .mini-cart_actions a:nth-child(2):hover, .page-title_actions .button:hover, .add-to-cart .button:hover {
  background-color: #195f2f;
}

/* override the css done by acumen to allow relative load of own svg files, relative to this files location */
.trigger-item--cart .trigger-icon, .skip-item--cart .skip-icon {
  @include svg (cart);
}

/* make product h1 more responsive */
@media only screen and (max-width: 480px) {
  h1.product-name {
    font-size: 13pt;
  }

  .breadcrumbs {
    li, * {
      font-size: 8pt;
    }
    .product {
      display: none;
    }
  }
}

/* expand navigation to 3 columns */
@media only screen and (min-width: 768px) {
  .nav-item.parent .menu {
    padding-bottom: 50px;
    width: 730px;
  }

  .nav-4.parent .menu {
    width: 440px;
  }

  .nav-5.parent .menu {
    width: 440px;
    left: -290px;
    &:before {
      right: 20px;
      left: initial;
    }
    &:after {
      right: 20px;
      left: initial;
    }
  }

  .nav-item.parent .menu-list .view-all {
    bottom: 0;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    width: 100%;
    background: #c7c7d8 none repeat scroll 0 0;
  }

  .nav-item.parent .menu-list li {
    border: medium none;
    float: left;
    width: 240px;
  }
}

/* adjust add to quote button on product pages so it doesn't hang off edge */
@media only screen and (max-width: 360px) {
  .pdp-purchase {
    .add-to-cart button {
      font-size: 15px;
      line-height: 40px;
      width: 133px;
    }
    .stepper {
      margin-right: 0;
    }
  }
  .product-qty .stepper_liner input, .product-qty .stepper_liner button {
    font-size: 15px;
    height: 40px;
    width: 50px;
  }
}

/* fix width overflow on mobile so scroll bar doesn't show */
@media only screen and (max-width: 480px) {
  .subscribe-box .input-text {
    flex: initial;
    width: 190px;
  }
  .pdp-secondary-actions {
    flex: inherit;
    width: 100%;
  }
  .grid { /* this is for the homepage */
    margin-left: auto;
    margin-right: auto;
  }
}

/* setup left and right columns for category header, left side for image and right side for content */
.catalog-header-left img {
  display: table;
  margin: auto;
  max-width: 150px;
  max-height: 150px;
}

.catalog-header-left {
  float: left;
  width: 270px;
}

.catalog-header-right {
  margin-left: 276px;
}

@media screen and (max-width: 640px) {
  .catalog-header-left img {
    max-height: 400px;
    max-width: 80%;
    display: block;
  }
  .catalog-header-left {
    float: none;
    width: auto;
    margin-bottom: 30px;
  }
  .catalog-header-right {
    margin-left: 0px;
  }
}

/* create content description expand and minimize box */
.expandable-content {
  height: auto;
}

.read-more-des, #read-more-bottom {
  background: #e8e8ef none repeat scroll 0 0;
  border: 1px solid #d0d0de;
  cursor: pointer;
  font-weight: bold;
  line-height: 20px;
  padding: 10px;

  &:hover {
    opacity: .8;
  }
}

#read-more-bottom {
  background: #d3d3d7 none repeat scroll 0 0;
  font-size: 14px;
  margin-bottom: 25px;
  padding: 5px 10px;
  border-top: none;
}

.read-more-des + .expandable-content.more {
  display: table;
  overflow: visible;
}

.read-more-des + .expandable-content.less {
  display: inherit;
  overflow: hidden;
  height: 79px;

}

.read-more-des + .expandable-content {
  border: 1px solid #d0d0de;
  border-top: none;
  padding: 10px;
}

/* fix ie11 bug where search, account, and quote buttons stick to right browser window */
@media only screen and (min-width: 980px) {
  header .trigger-list {
    width: 70% !important;
  }
}

/* adjust category h1 so that it doesn't take up as much space on mobile */
@media screen and (max-width: 640px) {
  .catalog-header h1 {
    font-size: 20pt;
  }
}

/* style the success page including google survey iframe */
#successpage {
  text-align: center;
  font-weight: 900;
}

#spreceived {
  font-size: 14pt; // fallback for vw
  font-size: 1.7vw;
  padding: 20px 0;
  color: #000;
}

#sprecblock {
  background-color: #1f6b37;
  color: #fff;
  font-size: 22pt; // fallback for vw
  font-size: 4vw;
  padding: 25px 15px; // fallback for vw
  padding: 2vw 1vw;
  text-transform: uppercase;
}

#spquotenum {
  background-color: #074c1d;
  color: #fff;
  font-size: 19pt; // fallback for vw
  font-size: 2vw;
  padding: 15px; // fallback for vw
  padding: .7vw;
  text-transform: uppercase;
}

#spiframe {
  width: 100%;
  min-height: 800px;
}

@media only screen and (max-width: 960px) {
  #spreceived {
    font-size: 14pt;
  }

  #sprecblock {
    font-size: 22pt;
    padding: 25px 15px;
  }

  #spquotenum {
    font-size: 19pt;
    padding: 15px;
  }
}

#algolia-searchbox {
  margin: 0 !important;
  label {
    display: none;
  }
  input {
    width: 100% !important;
  }
  .magnifying-glass {
    text-indent: -9999px;
    position: relative;
    width: 45px !important;
    height: 40px !important;;
    padding: 0;
    border: 0;
    border-radius: 0 3px 3px 0;
    background: #329af0 !important;
    top: 0 !important;
    right: 0 !important;
    &:before {
      background: url(../img/svg/search.svg) no-repeat;
      content: '';
      position: absolute;
      top: 8px;
      left: 50%;
      width: 24px;
      height: 24px;
      margin-left: -12px;
    }
  }
}

#tab-description {
  .std {
    max-width: inherit !important;
  }
}

